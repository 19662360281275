import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from './App';
import Logout from './Logout';

const Routes = createBrowserRouter([
    { path: '/:paramId', element: <App /> },
    { path: '/logout/:paramId', element: <Logout /> }, 
]);

function AppRouter() {
    return <RouterProvider router={Routes} />;
}

export default AppRouter;
