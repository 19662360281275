// Logout.js
import React, { useEffect } from 'react';
import { signOut } from 'aws-amplify/auth';
import { useNavigate,useParams } from 'react-router-dom';

const Logout = () => {
    const navigate = useNavigate();
    const params = useParams();
    useEffect(() => {
        const logout = async () => {
            try {
                await signOut();
                navigate('/'+params.paramId); // React Routerのナビゲーションを使用
            } catch (error) {
                console.error("ログアウト中にエラーが発生しました:", error);
            }
        };

        logout();
    }, [navigate]);

    return <div>ログアウトしました</div>;
};

export default Logout;
