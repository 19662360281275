import { Amplify } from 'aws-amplify';
import { Hub } from 'aws-amplify/utils';
import { fetchAuthSession, signOut } from 'aws-amplify/auth';
import { Authenticator, View, useTheme } from '@aws-amplify/ui-react';
import { I18n } from 'aws-amplify/utils';
import { messages } from "./i18n/amplify/messages";
import config from './amplifyconfiguration.json';
import React, { useEffect, useCallback } from 'react';
import { useParams, useNavigate} from 'react-router-dom';
import '@aws-amplify/ui-react/styles.css';


// Cognito設定読み込み
Amplify.configure(config);

// 日本語化
I18n.putVocabularies(messages);
I18n.setLanguage("ja");
function App() {
  const navigate = useNavigate();
  const params = useParams();
  const checkUser = useCallback(async (bool) => {
    try {
      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
      if (accessToken) {
        //メール検証成功時のセッション認証情報の更新処理
        if(bool){
          await fetchAuthSession({forceRefresh:true})
        }
        //メール検証済かチェック
        if (idToken.payload.email_verified === null || !idToken.payload.email_verified) {
          return
        }
        //token有効判定
        const now = Date.now() / 1000;
        const exp = accessToken.payload.exp
        if (exp < now) {
          await signOut()
          navigate('/' + params.paramId);
        }
        //ページ遷移フォームの定義
        const json = JSON.stringify(accessToken)
        const group = accessToken.payload['cognito:groups']
        let redirectUrl = "";
        if (params.paramId !== undefined) {
          if (params.paramId === "COM020M") {
            //解析状況確認
            redirectUrl = process.env.REACT_APP_REDIRECT_ANALYSIS_URL;
            //西空はアップロード選択のみ
            if (group !== undefined && group.includes(process.env.REACT_APP_NISIKU_GROUP)) {
              redirectUrl = process.env.REACT_APP_REDIRECT_UPLOAD_URL;
            }
          } else if (params.paramId === "COM030M") {
            //アップロード対象選択
            redirectUrl = process.env.REACT_APP_REDIRECT_UPLOAD_URL;
          }
        }

        const form = document.createElement('form');
        form.method = "post";
        form.action = redirectUrl
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = "token";
        hiddenField.value = json;
        form.appendChild(hiddenField);
        const hiddenField2 = document.createElement('input');
        hiddenField2.type = 'hidden';
        hiddenField2.name = "preparm";
        hiddenField2.value = params.paramId;
        form.appendChild(hiddenField2);
        document.body.appendChild(form);
        form.submit();
      } else {
        console.log('ユーザー情報またはトークンが見つかりません');
      }
    } catch (error) {
      console.log('ユーザーがサインインしていません', error);
    }
  }, [navigate]);
  //パスワードフィールドのみペースト禁止
  const handlePaste = (event) => {
    if(event.target.type === "password"){
      event.preventDefault();
    }
  };

  // 認証状態変更時にログイン状態をチェック
  useEffect(() => {
    Hub.listen('auth', (data) => {
      checkUser();
    });
    checkUser();
    document.addEventListener("paste", handlePaste);
    // クリーンアップ関数を返す
    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, []);

  //ヘッダー定義
  const components = {
    Header() {
      const { tokens } = useTheme();
      return (
        <View textAlign="center" padding={tokens.space.xs}>
          <h2>送電スマート保安サービス <br />on INFOPRISM（送電線異常検出）<br /> ログイン画面</h2>
        </View>
      );
    },
  }
  return (
    <Authenticator hideSignUp={true} components={components}>
      {({ signOut, user }) => (
        <main>
          ページを移動中・・・<br />
          遷移しない場合下記ボタンをクリックしてください<br />
          <button onClick={() => checkUser(true)}>ページを移動</button>
        </main>
      )}
    </Authenticator>
  );
}

export default App;
