export const messages = {
    ja: {
        "Back to Sign In": "ログイン画面に戻る",
        Confirm: "確認",
        Submit: "送信",
        "Confirm Sign Up": "サインアップの確認",
        "Confirmation Code": "確認コード",
        "Create Account": "新規登録",
        "Create a new account": "アカウントの新規登録",
        "Create account": "新規登録",
        Email: "メールアドレス",
        "Enter your code": "確認コードを入力してください",
        "Enter your Password": "パスワードを入力してください",
        "Enter your Username": "ユーザー名を入力してください",
        "Enter your username": "ユーザー名を入力してください",
        "Forgot your password?": "パスワードのリセット ",
        "Confirm Password": "パスワードの確認",
        "Please confirm your Password": "パスワードを入力してください",
        "Enter your Email": "メールアドレスを入力してください",
        "Have an account? ": "アカウント登録済みの方 ",
        "We Emailed You": "メールを送信しました ",
        Hello: "こんにちは ",
        "Incorrect username or password.": "ユーザー名またはパスワードが異なります",
        "Lost your code? ": "コードを紛失した方 ",
        "No account? ": "アカウント未登録の方 ",
        Password: "パスワード",
        "Phone Number": "電話番号",
        "Resend Code": "確認コードの再送",
        "Reset Password": "パスワードのリセット",
        "Reset your password": "パスワードのリセット",
        "Send code": "コードの送信",
        "Code":"コード",
        "Code *":"コード *",
        "New Password":"新しいパスワード",
        "Sign In": "ログイン",
        "Sign Out": "ログアウト",
        "Sign in": "ログイン",
        "Sign in to your account": "ログイン",
        "User does not exist": "ユーザーが存在しません",
        "Attempt limit exceeded, please try after some time":"試行制限を超えました。しばらく後に再試行してください",
        Username: "ユーザー名",
        "Username cannot be empty": "ユーザー名は必須入力です",
        "Username/client id combination not found.": "ユーザー名が見つかりません",
        "Confirm TOTP Code":"認証コードを入力してください",
        "Invalid code received for user":"無効なコードです",
        "Invalid verification code provided, please try again.":"無効な確認コードが入力されました。再度入力してください",
        "Account recovery requires verified contact information":"アカウントの回復には確認済みの連絡先情報が必要です",
        "Verify":"確認",
        "Skip":" ",
        "Your passwords must match":"パスワードが一致しません",
        "Password must have at least 8 characters":"パスワードは８文字以上入力してください"
    }
};